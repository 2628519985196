showInvoicesList = {
  showInvoicePreview: (data, requestStatus, xhrObject) ->
    show_shared_preview_invoce("Invoice Preview", data, "Go back")
  ,

  error: (xhrObj, textStatus, exception) ->
    alert('Sorry but something was wrong, please try again or contact your administrator.')
  ,
  getInvoiceForm: ->
    form =  $('#new_invoice')
    action = form.attr( 'action' )
    $.ajax({
      url: action + '/preview_html',
      type: 'GET',
      data: form.serialize(),
      timeout: 10000,
      success: showInvoicesList.showInvoicePreview,
      error: showInvoicesList.error
    })
  ,
  setup: ()->
    $('.invoice_preview').on('click', showInvoicesList.getInvoiceForm)
}

window.add_new_invoice_item = (element, location_required = true) ->
  table = $(element).parent().siblings('table')
  invoice_items_list = table.find('.invoice_items_list')
  new_invoice_item = $(element).closest('form').parent().find('.hidden_invoice_item').first()
  if(new_invoice_item.length > 0)
    new_invoice_item = new_invoice_item.clone()
    new_invoice_item.toggleClass('hidden_invoice_item visible_invoice_item')
    new_invoice_item.toggle(true)
    replace_invoice_item_id(new_invoice_item)
    new_invoice_item.find(".reset-required").val("")
    new_invoice_item.find("[data-invoice-item-field=description]").html("")
    new_invoice_item.find(".reset-required input").val("")
    new_invoice_item.find(".reset-required select").val("")
    new_invoice_item.find(".invoice-item-location").css("display", "none") if !location_required
    new_invoice_item.find("[data-invoice-item-field=id]").remove()
    new_invoice_item.hide()
    invoice_items_list.append(new_invoice_item)
    new_invoice_item.show(900)

RefreshSummary = {
  error: (xhrObj, textStatus, exception) ->
    $("#summary_collapsable").find('.spinner, .ui-widget-overlay').hide()
    alert('Sorry but something was wrong, please try again or contact your administrator.')
  ,
  renderSummaryPage: ->
    $("#summary_collapsable").find('.spinner, .ui-widget-overlay').show()
    $.ajax({
      url: '/applications/update_summary_page',
      type: 'GET',
      data: {
        current_authority: window.current_authority,
        current_app: window.current_app,
        application_id: $("[name='application[id]']").val()
      }
      dataType: 'html',
      timeout: 10000,
      success: (data, requestStatus, xhrObject) ->
        $("#summary_collapsable").find("[id$='-summary'] .col-xs-24").html(data)
        $("#summary_collapsable").find('.spinner, .ui-widget-overlay').hide()
      error: RefreshSummary.error
    })
  ,
  setup: ->
    $(RefreshSummary.renderSummaryPage) if $("#summary_collapsable").length > 0
}

show_success_message = true;
window.show_ajax_message = (msg, type, target = "#flash-message") ->
  type = 'success'  if type == 'notice'
  type = 'danger'   if type == 'error'
  type = 'danger'   if type == 'alert'
  flash = $(target)
  flash.find(".alert").slideUp('fast')
  flash.html "<div class='alert fade in alert-#{type} type-#{type}' style='display:none;'><button class='close' data-dismiss='alert'>×</button>#{msg}</div>"
  if(type == 'success')
    flash.find(".alert").slideDown().delay(5000).slideUp('slow') if ( show_success_message )
  else
    flash.find(".alert").slideDown()
  show_success_message = true

window.toggle_title_attr = (element, open_class) ->
  current_title = element.attr('title')
  if element.hasClass(open_class)
    element.attr('title', current_title.replace('Open', 'Close'))
  else
    element.attr('title', current_title.replace('Close', 'Open'))

select_prev_tab = ()->
  select_tab(-1)

select_next_tab = ()->
  select_tab(1)

select_tab = (index)->
  target = $('#tab-sidebar-area ul.nav li.active')
  while(index != 0 & target.length > 0)
    if index > 0
      target = target.next()
      index -= 1
    else
      target = target.prev()
      index += 1
  target.find('a').click() if(target.length > 0)

window.on_ajax_response = (e, status, xhr, data) ->
  window.tmp = { e: e, status: status, xhr: xhr, data: data }
  form_target = $(e.target)
  form_target.attr('data-modified', 'false')
  select_next_tab() if window.next_tab_on_submit
  window.next_tab_on_submit = false
  msg = xhr.getResponseHeader("X-Message")
  type = xhr.getResponseHeader("X-Message-Type")
  msg_empty = (!msg || !(msg.length > 0) || msg == 'Null' || msg == 'null' )
  type = (if(status == 'success') then "notice" else "error") if !type
  target = null
  if form_target.attr('data-type') == "json"
    target = form_target.closest('.modal')
    if(target.length > 0)
      modal_id = target.attr('id')
      target = target.find('.modal-alert-area')
      if status == 'success'
        $("##{modal_id} .close").click()
        uploader = $("[data-target='##{modal_id}']").closest('.file-uploader')
        if(uploader.length > 0)
          uploader.attr('has-file', 'true')
          file = eval("data.#{uploader.attr('data-target')}")
          link = uploader.find('.file-mode a')
          name = file.url.split('/').pop()
          link.attr('href', file.url)
          link.html(name)
    if(target.length > 0)
      target = target.attr('id')
    else
      target = null
  if msg_empty
    name_object = (form_target.attr('data-name-object') || 'action').capitalize()
    name_action = form_target.attr('data-name-action') || 'completed'
    msg = if (status == 'success') then "#{name_object} was successfully #{name_action}" else "#{name_object} cannot be #{name_action}"
  errors = if status != "success" && xhr.responseJSON then xhr.responseJSON else null
  mark_errors(errors)
  if target
    show_ajax_message msg, type, "##{target}"
  else
    show_ajax_message msg, type

control_dates = (id1, id2) ->
  input1 = $(id1)
  start = input1.val()
  format_start = input1.attr('data-format')
  if start
    format_start = format_start.split('/')
    index_year_start = (format_start.indexOf('yyyy') + 1) || (format_start.indexOf('yy') + 1)
    index_month_start = (format_start.indexOf('mm') + 1) || (format_start.indexOf('m') + 1)
    index_day_start = (format_start.indexOf('dd') + 1) || (format_start.indexOf('d') + 1)
    start = start.split('/')
    start = "#{start[index_year_start - 1]||''}#{start[index_month_start - 1]||''}#{start[index_day_start - 1]||''}"
  input2 = $(id2)
  end = input2.val()
  format_end = input2.attr('data-format')
  if end
    format_end = format_end.split('/')
    index_year_end = (format_end.indexOf('yyyy') + 1) || (format_end.indexOf('yy') + 1)
    index_month_end = (format_end.indexOf('mm') + 1) || (format_end.indexOf('m') + 1)
    index_day_end = (format_end.indexOf('dd') + 1) || (format_end.indexOf('d') + 1)
    end = end.split('/')
    end = "#{end[index_year_end - 1]||''}#{end[index_month_end - 1]||''}#{end[index_day_end - 1]||''}"
  if ( start && end && start > end )
    input2.val(input1.val())

update_subcontrator = ()->
  data = $('select#application_customization_attributes_subcontractor_company_id option:selected')
  for item in ['zip', 'name', 'city', 'state', 'phone', 'email', 'street-1', 'street-2', 'contact-first-name', 'contact-last-name', 'contact-mobile-phone']
    $("#subcontrator-#{item}").html(data.attr("data-#{item}") || '-')

window.check_form = (form)->
  popovers = []
  fields = form.find('[data-required=true]')
  fields_not_required = form.find('[data-required=false]')
  if fields.length > 0
    $.each(fields, () ->
      field = $(this)
      if(field.length > 0)
        current_container = field.closest('.form-group')
        current_value = (field.find('input:radio:checked').val() || "").toLowerCase() if field.hasClass('radio-area')
        if field.attr('data-setted')
          if(current_container.find('.line-answer-area .file-data').length > 0 || (current_container.find("[name$='[import_from_QLA_ID]']").val().length > 0 if current_container.find("[name$='[import_from_QLA_ID]']").length > 0))
            current_value = field.attr('data-setted').toLowerCase()
        current_value = (field.val() || "").toLowerCase() if !current_value

        if field.attr('type') == 'checkbox'
          current_value = false
          $.each(field.closest('.form-group').find('input.data'), ()->
            current_value = $(this).is(':checked')
            return false if current_value
          )

        attr_not_allowed = field.attr('data-not-allowed')
        if typeof attr_not_allowed != typeof undefined and attr_not_allowed != false
          parent = field.closest('.dropdown.dropdown-select')
          current_value = parent.find('.selected_value').text()
          current_value = '' if (current_value == field.attr('data-not-allowed'))

        if field.hasClass("location-selector")
          location_kind = field.closest('.line-answer-area').attr("data-question-type")
          simple_location = false
          location = false
          switch location_kind
            when "location", "advanced location"
              current_container = field.closest('.form-group')
              current_container_location = field.closest('.form-group').next('.form-group')
              current_value = current_container_location.find("input").val()
              location = true
            when "simple location"
              current_container = field.closest('.question-answer')
              street_area = current_container.find('.street-area')
              colection_inputs = street_area.children()
              current_value = ''
              simple_location = true
              colection_inputs.each () ->
                current_div = $(this)
                if current_div.hasClass("street-row")
                  input = current_div.find('input')
                  input_value = input.val()
                  if input_value.length > 0
                    current_value = input_value
                    simple_location = false
                    return false
                else
                  new_street_area = current_div.find('.col-xs-24')
                  new_inputs = new_street_area.children('.street-row')
                  new_inputs.each () ->
                    new_current_div = $(this)
                    new_input = new_current_div.find('input')
                    input_value = new_input.val()
                    if input_value.length > 0
                      current_value = input_value
                      simple_location = false
                      return false

        if((field.is(":visible") || (field.hasClass("location-selector") && field.closest('.form-group').next('.form-group').is(":visible") && location == true) || (field.hasClass("location-selector") && field.closest('.question-answer').find('.street-area').is(":visible") && simple_location == true) || field.attr("data-required-even-hidden") || (field.attr("data-type-dropdown-or-select") && field.parent().is(":visible"))) && !current_value)
          switch location_kind
            when "location", "advanced location"
              current_container_location = field.closest('.form-group').next('.form-group')
              current_container_location.addClass('with-error-group')

            when "simple location"
              current_container_location = field.closest('.question-answer').find('.street-area')
              current_container_location.addClass('with-error-group')
            else
              create_strong_label_tag(current_container.find('label.control-label'))
              current_container.addClass('with-error-group')
          show_error_message(current_container, 'This field cannot be empty')
          field.attr({'data-toggle':'popover', 'data-content':'Required field', 'title':'Warning'})
          popovers.push field
        else
          switch location_kind
            when "location", "advanced location"
              current_container_location = field.closest('.form-group').next('.form-group')
              current_container_location.removeClass('with-error-group')
            when "simple location"
              current_container_location = field.closest('.question-answer').find('.street-area')
              current_container_location.removeClass('with-error-group')
            else
              current_container.removeClass('with-error-group')
              remove_error_message(current_container)
    )
  fields = form.find('[data-max-file-size]')
  if fields.length > 0
    $.each(fields, () ->
      field = $(this)
      file = field.get(0).files[0]
      if(field.length > 0 && file)
        current_container = field.closest('.form-group')
        maxFileSize = field.attr('data-max-file-size')
        size = field.get(0).files[0].size
        if (size && maxFileSize && size > parseInt(maxFileSize))
          current_container.addClass('with-error-group')
          show_error_message(current_container, 'File size is bigger than expected')
          field.attr({'data-toggle':'popover', 'data-content':'Max size '+(maxFileSize/(1024*1024))+'MB', 'title':'Warning'})
          field.popover('show')
          popovers.push field
        else
          current_container.removeClass('with-error-group')
          remove_error_message(current_container)
    )
  return !(popovers.length > 0)

create_strong_label_tag = (label)->
  label_html = label.html()
  strong_already_added = label.find('strong').length > 0
  if label_html && !strong_already_added
    frag = document.createRange().createContextualFragment(label_html)
    tag = document.createElement("strong")
    tag.appendChild(frag)
    label.html(tag)

update_object_list = (current)->
  inputs = current.closest('.modal-content').find('input.form-control')
  data = {}
  key = null
  $.each(inputs, () ->
    input = $(this)
    data[input.attr('id')] = input.val()
    key = input.val() if key == null
  )
  target = $(".#{current.attr('data-create-object')}")
  object_type = target.attr('data-object')
  try
    objects = JSON.parse(target.val())
  catch e
    objects = {}
    console.log e
  objects[object_type] = {} if !objects[object_type]
  objects[object_type][key] = data
  target.val(JSON.stringify(objects))
  update_object_select(target, key)

update_object_select = (data_source, key=null)->
  object_type = data_source.attr('data-object')
  try
    objects = JSON.parse(data_source.val())
  catch e
    objects = {}
    console.log e
  target = $("[data-object-select=#{object_type}]")
  key = target.val() if !key
  options = ""
  if objects[object_type]
    for object_key, object_data of objects[object_type]
      object_data_params = ""
      for object_data_key, object_data_value of object_data
        object_data_params += "data-#{object_data_key}='#{object_data_value}' "
      object_data_params += "selected" if object_key == key
      options += "<option #{object_data_params} value='#{object_key}'>#{object_key}</option>\n"
  target.html(options)
  target.val(key)
  update_object_view(target)

update_object_view = (select)->
  object_type = select.attr('data-object-select')
  selected = select.find(":selected")
  data = selected.data()
  for data_key, data_value of data
    $(".object-#{object_type}-data-#{data_key}-value").html(data_value ||= '-'  )

strip_HTML = (text) ->
  container = document.createElement('div');
  container.innerHTML = text;
  return container.textContent || container.innerText;

window.update_conditional_questions = (parent_element = null, data_line_id = null)->
  auto_fill_questions = ''
  if parent_element
    conditional_questions = $(parent_element).find('[data-conditional-action][data-conditional-action!=""][data-conditional-target][data-conditional-target!=""]')
    auto_fill_questions = $(parent_element).find("[data-autofill-ref][data-autofill-ref='#{data_line_id}']") if data_line_id
  else
    conditional_questions = $('[data-conditional-action][data-conditional-action!=""][data-conditional-target][data-conditional-target!=""]')
  auto_fill_questions = $("[data-autofill-ref][data-autofill-ref='#{data_line_id}']") if data_line_id && auto_fill_questions.length < 1
  $.each(conditional_questions, ()->
    current_conditional_question = $(this)
    content = current_conditional_question.closest('.modal-body')   #content in applicant view

    content = current_conditional_question.closest('.tab-pane').find('[data-shoot-code]') if (content.length < 1) && $('body').hasClass('administrator-panel')
    content = current_conditional_question.parent() if content.length < 1
    content.each ()->
      try
        current = $(this).find(current_conditional_question)
        action = current.attr('data-conditional-action')
        target = current.attr('data-conditional-target')
        target = target.toLowerCase() if target
        target = $(this).find("[data-name='#{target}']")
        activated = !(target.length > 0)
        if target.length > 0
          data = obtain_data(target)
          value = data['value']
          location_data = data['location_data']
          location_type = data['location_types']
          expression = current.attr('data-conditional-expression')
          activated = eval(expression) && target.closest('.subform-answer-area').closest('.form-group.question-group').css('display') != 'none'
        if action
          switch action.toLowerCase()
            when "show"
              if activated
                current.show()
                current.find('.question-answer [type=hidden]').each () ->
                  $(this).attr('value', 'false') if $(this).attr('id') && $(this).attr('id').endsWith('reset')
              else
                current.hide()
                is_reset = false
                current.find('.question-answer [type=hidden]').each () ->
                  if $(this).attr('id') && $(this).attr('id').endsWith('reset')
                    $(this).attr('value', 'true')
                    is_reset = true
                current.find('input, select, textarea').val('') if !is_reset

            when "hide"
              if activated then current.hide() else current.show()
      catch e
        console.log "Error:#{e}:#{expression}"

  )
  $('.panel').each () ->
    current_panel = $(this)
    collapsable = current_panel.find('.panel-collapse')
    if collapsable.length > 0
      has_visible_items = false
      collapsable.find('.panel-body > .row.form-group').each ()->
        collapsable_form_group = $(this)
        if (collapsable_form_group.css('display') != "none")
          has_visible_items = true
      current_panel.toggle(has_visible_items)

  setTimeout( ()->
    if data_line_id
      auto_fill_questions.each ()->
        current = $(this)
        current.find("input[name*='[value]'], textarea[name*='[value]']").val($("[data-line-id=#{current.attr('data-autofill-ref')}]").find("input[name*='[value]'], textarea[name*='[value]']").val())

  , 200)

  refresh_all_maps() # Locations maps
  refresh_map_list() # Not locations maps
  refresh_shoot_status() # Shoot status
  update_sidebar_status() # Sidebar

# Used to update the status of the sidebar in the applicant view.
# It checks every required field and marks the tab completed if every required field is filled with a right value
window.update_sidebar_status = (redirect=false)->
  sidebar = $('#tab-sidebar-area')
  if sidebar.length > 0
    full_sidebar_completed = true
    $('#tab-content-area .tab-content .tab-pane.tab-item').each ()->
      current = $(this)
      id = current.attr('id')
      sidebar_button = sidebar.find("[href='##{id}']").closest('li')
      completed = true
      current.find('[data-object-name=application] .form-group.question-group.summary-field:not([style="display: none;"]) [data-required=true]').each ()->
        current_field = $(this)
        current_type = current_field.attr('type')
        current_type = "radio"  if current_field.hasClass('radio-area')
        switch current_type
          when 'checkbox'
            completed = false   if !current_field.is(':checked')
          when 'radio'
            completed = false   if current_field.find('input:radio:checked').length == 0
          when 'file'
            completed = false   if !current_field.val() && (!current_field.attr('data-setted') || (!current_field.attr('data-setted').length > 0))
          else
            # Filtering when the dynamic object is set with a wrong value
            wrong_dynamic_object_value = false
            can_be_blank = false
            is_a_dynamic_object = current_field.closest('.value-existing-area', '.value-ado-existing-area').find('.dropdown-select').length
            if is_a_dynamic_object
              can_be_blank = $(this).closest('.value-existing-area').find('.dropdown-select').is(':hidden')
            if current_field.parents('.value-area').length && current_field.closest('.dynamic-object-field').length
              wrong_dynamic_object_value = wrong_dynamic_object_values.includes(current_field.val())
            completed = !!if current_field.hasClass('location-selector')
              location_name_field = current_field.closest('.form-group').next().find('.location-name')
              current_field.val() || location_name_field.val()
            else
              (can_be_blank || current_field.val()) && !wrong_dynamic_object_value
      shoots_list = current.find('.application_shoots_list')
      if(shoots_list.length > 0)
        completed = false if !(shoots_list.find('.table.wice-grid tbody .status-complete.data-not-archived').length > 0)
        completed = false if  (shoots_list.find('.table.wice-grid tbody .status-incomplete').length > 0)
      full_sidebar_completed = full_sidebar_completed && completed
      sidebar_button.toggleClass('completed incompleted') if (sidebar_button.attr('data-is-new') != 'true') && (sidebar_button.hasClass('completed') != completed)
      if redirect && !completed
        redirect = false
        sidebar_button.find('a').click()

    submit_application_buttons = $('.btn-application-submit[data-save-system=single], .btn-enquiry-submit[data-save-system=single]')
    submit_application_buttons.each ()->
      current_submit_application_button = $(this)
      if( full_sidebar_completed != current_submit_application_button.hasClass('ready') )
        current_submit_application_button.toggleClass('ready no-ready')
        current_submit_application_button.attr('disabled', !full_sidebar_completed )

update_document_list = (callback = null)->
  target = $('#documents_summary form')
  if(target.length > 0)
    $.ajax({
      method: "get",
      url: window.current_documents
    }).success( (result)->
      target.html(result)
      callback() if callback
    ).fail( (result)->
      console.log result
    )

window.current_tab_can_be_checked = ()->
  current_panel = $('#tab-content-area .tab-content .tab-pane.tab-item:visible')
  id = current_panel.attr('id')
  sidebar_button = $("#tab-sidebar-area [href='##{id}']").closest('li')
  sidebar_button.attr('data-is-new', 'false')
  update_sidebar_status()
  $('body').animate({scrollTop: 0}, '500', 'swing' )

window.allow_select_current_status = (e)->
  current = $(".no-allow-current-application")
  current_list_li = current.closest('li')
  current_list_li.each ->
    $(this).addClass( "no-allowed-li" )

# -----------------------------------------------------------------------------
# save_application
# Params
# - Application id
# Description
# - Clone inputs into application form and submit the form
# Return
# - void
# -----------------------------------------------------------------------------
window.save_application = (target, submit=false) ->
  submit_form = $("form[data-target=#{target}]")
  inputs_area = submit_form.find(".submit-inputs-area")
  inputs_area.html('')
  if(inputs_area.length > 0)
    inputs  = $("form[data-application=#{target}] [name^='application[answer_elements_attributes]']").clone()
    inputs.attr('id',null)
    inputs_area.append(inputs)
    submit_form.attr('data-remote', null) if submit
    submit_form.submit()

# auto_display_settings_management
# call to the function auto_display_settings with a different selector
auto_display_settings_management = () ->
  auto_display_settings($('#app_authority_allow_split_payment'), true) if $('#app_authority_allow_split_payment').length > 0
  auto_display_settings($('#app_authority_enquiry_available'), true) if $('#app_authority_enquiry_available').length > 0
  auto_display_settings($('#app_authority_allow_waivers'), true) if $('#app_authority_allow_waivers').length > 0

window.initialize_main_scripts = (content)->
  load_locations()
  init_map_answer($('.map-answer-container'))
  update_conditional_questions()
  refresh_shoot_status()
  load_format_tools()
  load_dynamic_objects()
  load_field_map()
  refresh_modal_actions()
  init_editor()
  load_dynamic_elements()
  force_update_show_when()
  auto_display_settings_management()
  # check_form(modal_form_to_ckeck.find('form'))
  $('.wysihtml5:visible').each ()->
    init_ckeditor($(this))

window.evaluate_javascript_for_reminders_tab  = () ->
  load_format_tools()

window.evaluate_javascript_for_production_tab = () ->
  update_conditional_questions()
  load_format_tools()
  load_dynamic_objects()

window.evaluate_javascript_for_financial_tab = () ->
  showInvoicesList.setup()
  load_suffix_invoice()
  add_new_invoice_item({id: 'btn_create_item_'}, true)

  $('.hidden_invoice_item').each ()->
    current = $(this)
    current.find('* [disabled]').not('[data-invoice-item-field="vat"]').not('[data-invoice-item-field="total"]').attr({disabled: false, readonly: false, enabled: true})
    current.toggle(false)
    current.insertAfter(current.closest('form'))

  load_format_tools()

window.evaluate_javascript_for_messages_tab = () ->
  load_format_tools()
  refresh_message_button($('#new_message'))

  editor = CKEDITOR.instances.message_notes
  if(editor)
    editor.on 'change', ()->
      refresh_message_button($(document).find('#new_message'))
      editor.resetDirty()


  for instance in $('.trix-content')
    block_empty_fields(instance.id)
    is_new_message = (instance.id == 'message_notes')

    if is_new_message
      instance.addEventListener('keyup', (e) -> set_new_message_submit_availability(e.target.innerText.length))
    else
      instance.addEventListener('keyup', (e) -> set_internal_message_submit_availability(e.target))

  current_message = if(window.current_message) then $("[data-code='#{window.current_message}']") else null
  if(current_message && current_message.length > 0)
    panel = current_message.closest('.tab-pane')
    list_item = $(".admin-messaging-list-area .admin-messaging-nav a[href='##{panel.attr("id")}']")
    list_item.click()
    setTimeout(()->
      if(!current_message.hasClass('internal-message-expanded'))
        current_message.find('.message-toggle-icon').click()
    , 200)
  else
    $(document).on('click', '[data-tab-id="messages"]', (e)->
      $('[href=#new-message-area]').click() if !$('.admin-messaging-list-area .admin-messaging-nav li').length > 0
      $('.admin-messaging-nav a:first').click()
      $('.internal-message-show-item.internal-message-collapsed:last .message-toggle-icon').click()
    )
    if(!($('[data-tab-id="messages"]').length > 0))
      $('.internal-message-show-item.internal-message-collapsed:last .message-toggle-icon').click()

  $('.multiselect li').on 'click', (e)->
    return if $(this).closest('#reminders_collapsable').length

    e.stopPropagation()
    $(this).find('input[type="checkbox"]').click()

  $('.multiselect input[type="checkbox"]').on 'click', (e)->
    el = $(this)
    return if el.closest('#reminders_collapsable').length

    e.stopPropagation()
    if el.is(':checked')
      new_mail = el.val()
      textarea  = el.closest('.mail-contacts-area').find('.full-mail-contacts-text')
      old_mails = textarea.val()
      new_mails = if (old_mails.lastIndexOf(',') != (old_mails.length-2) && (old_mails.length > 0)) then ', ' else ''
      new_mails = new_mails + "#{new_mail}"
      textarea.val(old_mails + new_mails)
    else
      new_mails = el.val()
      textarea  = el.closest('.mail-contacts-area').find('.full-mail-contacts-text')
      textarea_value = textarea.val()
      #found the email in the textarea
      if (textarea_value.indexOf(new_mails)!=-1)
        #delete the email in the textarea
        delete_value_textarea(textarea, new_mails)

  $("button[name='all_contacts']").on 'click', (e)->
    textarea = $('.full-mail-contacts-text')
    textarea_value = textarea.val()
    $('.multiselect input[type="checkbox"]').each ->
      elemento = $(this)
      if elemento.is(':checked')
        elemento.prop('checked', false) if(textarea_value.lastIndexOf(elemento.val()) == -1)

  $('ul.admin-messaging-nav').find('li:first a').click()

show_error_message = (container, message) ->
  if $(container).find('.uncompleted-shoot-message').length == 0
    $(container).append('<p class="uncompleted-shoot-message">'+ message + '</p>')

remove_error_message = (container) ->
  $(container).find('.uncompleted-shoot-message').remove()

action_performed_on_credit_note_elem = (target) ->
  credit_note_elem_ids = "#new-credit-note, #credit-notes"
  target.closest(credit_note_elem_ids).length > 0

action_performed_on_edit_doc = (target) ->
  credit_note_elem_ids = ".cancel-edit, .doc-information"
  target.closest(credit_note_elem_ids).length > 0

$ ->
  $(RefreshSummary.setup)

  new_system_submit = $('.btn-application-submit[data-save-system=multi]')
  new_system_submit.attr('disabled', false)       if new_system_submit.attr('disabled')
  new_system_submit.toggleClass('ready no-ready') if new_system_submit.hasClass('no-ready')

  allow_select_current_status()

  $(document).on('shown.bs.tab', '[role="tabpanel"]', (e)->
    if e.target.closest('li').getAttribute('data-is-new') == 'true'
      current_tab_can_be_checked()
  )

  $(document).on('click', "input[type='submit']", (e)->
    if !check_form($(this).closest('form')) && $(this).closest('form').attr('id') == 'new_reminder'
      alert("We're sorry. Some fields are missing required data.")
      e.preventDefault()
    else
      e.preventDefault() if !check_form($(this).closest('form')) && ( if ($(this).closest('form')[0].id == 'new_document') then true else (!window.confirm("We're sorry. Some fields are missing required data. Press cancel to continue editing or ok to save with incomplete data.")) )
  )

  $(document).on 'submit', '#new_document', ()->
    $(this).find('.upload-doc').attr('disabled', true) if $(this).find('.upload-doc').length > 0
    $.ajax({
      method: "get",
      url: 'documents',
    }).success( (result)->
    ).fail( (result)->
      $(this).find('.upload-doc').attr('disabled', false) if $(this).find('.upload-doc').length > 0
    )

  $(document).on 'click', '#tab-sidebar a[data-no-toggle="tab"], .btn-application-submit[data-save-system=single]', (e)->
    window.next_tab_on_submit = false
    e.preventDefault()
    current = $(this)
    to_submit = $("form[data-autosubmit='true'][data-modified='true']")
    current_tab_can_be_checked()
    if(to_submit.length > 0)
      if( check_form(to_submit) || ( window.confirm("We're sorry. Some fields are missing required data. Press cancel to continue editing or ok to save with incomplete data.") ) )
        show_success_message = false
        to_submit.submit()
        current.tab('show')
    else
      current.tab('show')

  $(document).on 'click', '.select-prev-tab', ()->
    current_tab_can_be_checked()
    select_prev_tab()

  $(document).on 'click', '.select-next-tab', ()->
    current_tab_can_be_checked()
    select_next_tab()

  window.next_tab_on_submit = false
  $(document).on 'click', '.select-next-tab-on-submit', ()->
    current_tab_can_be_checked()
    current = $(this)
    form = current.closest('form')
    if(form && (form.length > 0))
      window.next_tab_on_submit = true
    else
      select_next_tab()

  $(document).on 'click', '[data-toggle]', ()->
    refresh_map_list()
    auto_display_settings_management() if $(this).attr('data-toggle') == 'tab'

  $(document).on("ajax:success", "form[data-remote='true'], a[data-remote='true']", (e, data, status, xhr) ->
    update_document_list(()->
      $('[data-toggle="tooltip"]').tooltip()
      $('[data-toggle="popover"]').popover()
    )

    current = $(e.target)
    return if action_performed_on_credit_note_elem(current)
    return if action_performed_on_edit_doc(current)

    on_ajax_response(e, status, xhr, data)

    url_prefix = if data && data['authority'] then "/#{data['app']['name']}/#{data['authority']['country']}/#{data['authority']['name']}" else ""
    if(current.attr('data-remote') == 'true')
      if(current.attr('data-method') == 'delete' || current.attr('data-method') == 'archive')
        current.closest('tr').remove()
      if(current.attr('id') == 'new_document' && current.is(':visible'))
        form = current
        if $('body').hasClass('administrator-panel')
          add_new_document($('.doc-uploaded'), data, url_prefix)
        restore_document_values(form)
        refresh_document_status(form)
      if(current.attr('id') == 'new_message')
        row = "<tr class='even'><td>#{data['id']}</td><td>#{data['subject']}</td><td>#{data['name_from']}</td><td>#{data['created_at']}</td><td><a class='show-message' data-subject='#{data['subject']}' data-message='#{data['text']}' href='#'>View</a></td><td><a data-remote='true' href='#{url_prefix}/messages/#{data['id']}/archive'>Archive</a></td><td><a data-remote='true' href='#{url_prefix}/messages/#{data['id']}/forward'>Send Again</a></td></tr>"
        $('body #message_summary table tbody').prepend(row)
      if(current.attr('id') == "new_subcontrator")
        $('#new_subcontrator .close').click()
        new_option = "<option data-email='#{data['email']}' data-name='#{data['name']}' data-city='#{data['city']}' data-phone='#{data['phone']}' data-state='#{data['state']}' data-street-1='#{data['street_1']}' data-street-2='#{data['street_2']}' data-zip='#{data['zip']}' value='#{data['id']}'>#{data['name']}</option>"
        subcontrata_selector = $('select#application_customization_attributes_subcontractor_company_id')
        if subcontrata_selector
          subcontrata_selector.append(new_option)
          subcontrata_selector.val(data['id'])
          update_subcontrator()
      if(current.attr('id') == 'new_reminder')
        form = $('form[id="new_reminder"]')
        restore_reminder_values(form)
      replace_source = current.attr('action')
      replace_target = current.attr('data-callback-replace')
      if replace_source && replace_target
        $.ajax({
          url:      replace_source,
          method:   'get',
          # dataType: 'json'
        }).done( (result)->
          if(result)
            $(replace_target).html(result)
            set_drag_and_drop($(replace_target))
            setTimeout( ()->
              initialize_main_scripts();
            , 200 )
        ).fail( (result)->
          alert('Sorry but something was wrong, please try again or contact your administrator. Error ID#JS_F-APPL_001')
        )
      $('#issue-permit-item').toggle(data['can_be_issued'])    if(data && data['can_be_issued'] != undefined)
      $('#extend-permit-item').toggle(data['can_be_extended']) if(data && data['can_be_extended'] != undefined)
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
    $(RefreshSummary.setup)
  ).on("ajax:error", "form[data-remote='true'],a[data-remote='true']", (e, xhr, status, error) ->
    on_ajax_response(e, status, xhr, error)
  )

  $(document).on("change", 'select#application_customization_attributes_subcontractor_company_id', ()->
    update_subcontrator()
  )

  $(document).on("change", "form[data-autosubmit='true']", ()->
    current = $(this)
    current.attr('data-modified', true)
  )

  $(document).on("change", '.data, .location-type-selector', ()->
    update_conditional_questions($(this).closest('form'), $(this).closest('[data-line-id]').attr('data-line-id'))
  )

  $(document).on("input", '.data.eve_assisted_dynamic_object', ()->
    update_conditional_questions()
  )

  $(document).on("change", '.location-street-name', ()->
    refresh_shoot_status()
  )

  update_conditional_questions()


  $(document).on("click", ".remove-current-file", ()->
    current = $(this)
    current.closest(".file-uploader").attr('has-file', 'false')
  )

  $(document).on("change", "#application_project_start_date, #application_project_start_time_4i, #application_project_start_time_5i", ()->
    control_dates('#application_project_start_date','#application_project_end_date')
  )

  $(document).on("change", "#application_project_end_date, #application_project_end_time_4i, #application_project_end_time_5i", ()->
    control_dates('#application_project_start_date','#application_project_end_date')
  )

  $(document).on("change", "#application_customization_attributes_metal_plates_date_installed, #application_customization_attributes_metal_plates_date_removed", ()->
    control_dates('#application_customization_attributes_metal_plates_date_installed','#application_customization_attributes_metal_plates_date_removed')
  )

  $(document).on("click", ".show-message", ()->
    current = $(this);
    subject = current.attr('data-subject');
    message = current.attr('data-message');
    modal_dialog = $('#modal_message')
    modal_dialog.find('.message-subject').html(subject)
    modal_dialog.find('.message-body').html(message)
    modal_dialog.modal('show')
  )

  $(document).on("click", "[data-modal-confirm]", (e)->
    e.preventDefault()
    current = $(this)
    message = current.attr('data-modal-confirm')
    link = current.attr('data-modal-url')
    modal_dialog = $('#generic-modal')
    modal_dialog.find('.modal-title').html(message)
    modal_dialog.modal('show')
    modal_dialog.find('#send_acept').attr('href',link)
  )

  $(document).on("click", "#send_acept", (e)->
    mns = $('#txtchek')
    if(!$("#ischeckSelected").is(':checked'))
      mns.show()
      e.preventDefault()
    else
      mns.hide()
  )

  $(document).on("click", ".more-info-action", ()->
    current = $(this);
    modal_dialog = $('#modal_comment')
    for item in ['action', 'change', 'created', 'user', 'message']
      modal_dialog.find(".comment-#{item}").html(current.attr("data-#{item}"))
    modal_dialog.modal('show')
  )

  $(document).on("click", "[data-create-object]", ()->
    current = $(this)
    update_object_list(current)
  )

  $(document).on("change", "[data-object-select]", ()->
    current = $(this)
    update_object_view(current)
  )

  $(document).on("change", "select.dashboard-select", ()->
    current = $(this)
    target = current.find('option:selected').attr('data-url')
    window.location = target if target
  )

  $(document).on("change", "[data-action-on-change]", ()->
    current = $(this)
    action = current.attr("data-action-on-change")
    if action
      eval(action)
  )

  $(document).on('click', '.save-without-update[data-save-system=single]', (e) ->
    e.preventDefault()
    if $(this).hasClass('connect-stripe-button')
      url = $(this).attr('href')
      action_on_yes = "$(document).find('#spinner, #overlay').show(); $('#main-content form:visible[data-remote=\"true\"]').submit(); setTimeout(function () { window.location.href = '#{url}';}, 1500); "
      show_shared_modal('Please confirm!',"Changes in your application are going to be saved and you are going to be redirected to the Stripe Platform. \n After finishing, Stripe will redirect you to our system and you will be able to complete your application.", "Yes", "No", action_on_yes)
    else
      $('#main-content form:visible[data-remote="true"]').submit()
  )

  $(document).on('click', '.save-without-update[data-save-system=multi]', (e) ->
    e.preventDefault()
    current = $(this)
    target  = current.attr("data-target")
    save_application(target)
  )

  $(document).on('click', 'div.glyphicon.glyphicon-triangle-bottom, div.glyphicon.glyphicon-triangle-top', () ->
    arrow = $(this)
    toggle_title_attr(arrow, 'glyphicon-triangle-bottom')
    if arrow.hasClass('animate-while-load')
      rotate_arrow(arrow)
      arrow.removeClass('animate-while-load')
    else
      arrow.toggleClass('glyphicon-triangle-bottom glyphicon-triangle-top')
  )

  rotate_arrow = (arrow) ->
    $(deg: 0).animate { deg: 180 },
      duration: 4000,
      step: (now) ->
        arrow.css transform: 'rotate(' + now + 'deg)'

  $(document).on('click', '[data-priority-url]', ()->
    current = $(this)
    priority_url = current.attr('data-priority-url')
    if priority_url && !current.hasClass('pink')
      $.ajax({
        method: "get",
        url: priority_url
      }).success( (result)->
        current.toggleClass('fa-star-o fa-star')
      ).fail( (result)->
        console.log 'fail', result
      )
  )

  $(document).on('click', '[data-document-url]', (e)->
    current = $(this)
    document_url = current.attr('data-document-url')
    if document_url
      window.location.href = document_url
      set_current_hash()
  )

  $(document).on('click', '[data-messages-url]', (e)->
    current = $(this)
    messages_url = current.attr('data-messages-url')
    if messages_url
      window.location.href = messages_url
      set_current_hash()
  )

  $("[data-action-on-change]").each ()->
    current = $(this)
    action = current.attr("data-action-on-change")
    eval(action) if action

  $($("input[data-object]").each ()->
    current = $(this)
    update_object_select(current)
  )

  $(document).on('click', '.btn-enquiry-submit.ready', ()->
    $('form[data-autosubmit=false]').last().submit()
  )

  $(document).on('click', "[data-target^='#collapse-summary-']", ()->
    $(this).find('.arrow').toggleClass 'down-arrow-icon up-arrow-icon'
  )

  # When an input value is changed, it checks if it has a red outline and it is removed if necessary
  $(document).on('change', 'input, textarea', ()->
    if $(this).closest('.line-answer-area').attr("data-question-type") == 'simple location'
      root_element = $(this).closest('.line-answer-area').find('.street-area')
    else
      root_element = $(this).closest('.form-group')
    check_and_remove_error_class($(this), root_element)
  )

  DATEFORMATTYPES = '[data-format-type="date"], [data-format-type="datetime"], ' +
    '[data-format-type="time"], [data-format-type="time_half_hour"]'

  $(document).on('dp.change', DATEFORMATTYPES, ()->
    root_element = $(this).closest('.form-group')
    check_and_remove_error_class($(this), root_element)
  )

  check_and_remove_error_class = (element_changed, root_element)->
    if root_element.hasClass('with-error-group') && element_changed.val().length > 0
      root_element.removeClass('with-error-group')
      remove_error_message(root_element)
      label = root_element.find('label.control-label')
      strong_html = label.find('strong').html()
      label.html(strong_html) if strong_html

  radio_buttons = $('input[type="radio"]')
  for radio_button in radio_buttons
    $(radio_button).focus () ->
      $(this).next().addClass('focused')
    $(radio_button).blur () ->
      $(this).next().removeClass('focused')

  $(document).on('focus', ".apply4_logo>a", ()->
    $(this).find('img').addClass('focused')
  )

  $(document).on('blur', ".apply4_logo>a", ()->
    $(this).find('img').removeClass('focused')
  )

  initialize_map($('#map-container'), $('#application_project_location_latitude'), $('#application_project_location_longitude'))

  handleTabClick = (event) ->
    tab_id = $(this).attr('data-tab-id')
    return if ( $("##{tab_id}").attr('data-already-loaded') == 'true' )

    data_tab_content_url = $(this).attr('data-tab-content-url')
    javascript_to_load = $(this).attr('data-javascript-to-load')

    $('#spinner-123').remove()

    load_tab_content(data_tab_content_url, tab_id, javascript_to_load)


  $(document).on('click', 'ul.nav.nav-tabs.nav-justified li a[data-tab-content-url]', handleTabClick)

  load_tab_content = (data_tab_content_url, tab_id, javascript_to_load) ->
    $("##{tab_id}").removeAttr('style')
    $('.row.tab-content').children().removeClass('active')


    $("ul.nav.nav-tabs.nav-justified li a:not([data-already-loaded='true'])").removeAttr('data-toggle')
    $("ul.nav.nav-tabs.nav-justified li:not([data-already-loaded='true'])").addClass('disabled')

    $(document).off('click', 'ul.nav.nav-tabs.nav-justified li a[data-tab-content-url]', handleTabClick)


    $(".row.tab-content ##{tab_id}").append('<div id="spinner-123" style="min-height: 250px;" class="align-middle text-center"><i class="fa fa-spinner fa-spin fa-3x center-block" style="color: #bbb;"></i></div>')

    if(data_tab_content_url)
      tab_content_target = $('.row.tab-content')
      request = {
        method: "get",
        url: data_tab_content_url
      }

      tab_content_target.parent().siblings('.spinner, .ui-widget-overlay').show(100)

      $.ajax(
        request
      ).done( (result)->
        $('#spinner-123').remove()

        $("a[data-tab-id='#{tab_id}']").attr('data-already-loaded','true')
        $("a[data-tab-id='#{tab_id}']").parent().attr('data-already-loaded','true')
        $("##{tab_id}").attr('data-already-loaded','true')

        $('ul.nav.nav-tabs.nav-justified li a').attr('data-toggle','tab')
        $('ul.nav.nav-tabs.nav-justified li').removeClass('disabled')

        $(document).on('click', 'ul.nav.nav-tabs.nav-justified li a[data-tab-content-url]', handleTabClick)

        $(".row.tab-content ##{tab_id}").append( result )

        function_to_evaluate = window[javascript_to_load]

        function_to_evaluate() if(function_to_evaluate)
      ).fail( (result)->
        target.parent().siblings('.spinner, .ui-widget-overlay').hide()
        alert('Sorry but something went wrong. Please try again or contact your administrator.')
      )
