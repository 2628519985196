showReminderList = {
  hideReminderList: ->
    $('#reminders-lateral-panel').hide()
    $(window).off('click')
    $('dropdown').off('show.bs.dropdown')
  ,
  showReminderDiv: (data, requestStatus, xhrObject, element) ->
    $(element).children().addClass('open') unless $(element).children().hasClass('open')
    $('#reminders-lateral-panel').html(data).show()
    $(window).on('click', (e) ->
      if !$(e.toElement).closest('.reminder-panel').length > 0 || $(e.toElement)[0].hasAttribute('data-close-reminder')
        showReminderList.hideReminderList()
      else
        $(element).children().addClass('open') unless $(element).children().hasClass('open')
    )
    $('.dropdown').on('show.bs.dropdown', (e)->
      if !$(e.target).parent().hasClass('type-of-reminder-selector')
        showReminderList.hideReminderList()
      else
        $(element).children().addClass('open') unless $(element).children().hasClass('open')
    )
  ,
  updateReminderDiv: (data, requestStatus, xhrObject, element) ->
    $('#reminders-lateral-panel').html(data).show()
    $('.dropdown').on('show.bs.dropdown', (e)->
      if !$(e.target).parent().hasClass('type-of-reminder-selector')
        showReminderList.hideReminderList()
      else
        $(element).children().addClass('open') unless $(element).children().hasClass('open')
    )
  ,
  error: (xhrObj, textStatus, exception) ->
    alert('Sorry but something was wrong, please try again or contact your administrator.')
  ,
  getRemindersList: ->
    parent_element = $(this)
    $.ajax({
      url: '/reminders/reminder_lateral_panel',
      type: 'POST',
      data:
        reminder_type: $(this).attr('id'),
        current_authority: window.current_authority,
        current_app: window.current_app,
        applications: window.applicationsIDList
      ,
      dataType: 'html',
      timeout: 10000,
      success: (data, requestStatus, xhrObject) ->
        showReminderList.showReminderDiv(data, requestStatus, xhrObject, parent_element)
      error: showReminderList.error
    })
  ,
  changeRemindersList: ->
    type_of_reminder =  $(this).closest('.reminder-panel').children().first().attr('reminders-status')
    parent_element = '#top-'+type_of_reminder+'-reminders'
    parent_element = $(parent_element)
    $.ajax({
      url: '/reminders/reminder_lateral_panel',
      type: 'POST',
      data:
        reminder_type: $(this).closest('.reminder-panel').children().first().attr('reminders-status'),
        current_authority: window.current_authority,
        current_app: window.current_app,
        reminder_category_filter: $(this).val(),
        applications: window.applicationsIDList
      ,
      dataType: 'html',
      timeout: 10000,
      success: (data, requestStatus, xhrObject) ->
        showReminderList.updateReminderDiv(data, requestStatus, xhrObject, parent_element)
      error: showReminderList.error
    })
  ,
  setup: ->
    $('#top-new-reminders, #top-due-reminders').on('click', showReminderList.getRemindersList)
    $(document).on('change', '#reminders_category_selector', showReminderList.changeRemindersList)
}

filterReminders = {
  run: (checkbox) ->
    if checkbox.attr('name') == 'reminders_all'
      filterReminders.clean_filters()
    else
      filterReminders.filter()
  ,
  clean_filters: ->
    $('#others-reminders .list-group-item, #completed-reminders .list-group-item').show()
    $('#reminders-filter input[type="checkbox"]').removeAttr('checked')
  ,
  filter: ->
    selected_types = $('#reminders-filter input[type="checkbox"]:checked').map (index, checkbox) ->
      $(checkbox).attr('name').toLowerCase()
    .get()
    if selected_types.length == 0
      filterReminders.clean_filters()
    else
      $('#others-reminders .list-group .type, #completed-reminders .list-group .type').each ->
        if selected_types.indexOf($(this).text().toLowerCase()) > -1
          $(this).closest('.list-group-item').show()
        else
          $(this).closest('.list-group-item') .hide()
  ,
  setup: ->
    $(document).on('change', '#reminders-filter input[type="checkbox"]', ->
      filterReminders.run($(this))

    )
}


$(filterReminders.setup)
$(showReminderList.setup)

window.restore_reminder_values = (form) ->
  form.find(':input').each ->
    if $(this).attr("type") == 'text'
      $(this).val('')

$(document).on('click', "[data-target^='#collapse-reminder-']", ()->
  $(this).closest('.list-group').find('.arrow').toggleClass 'down-arrow-icon up-arrow-icon'

)

$(document).on('click', "#reminder_status", ()->
  $(this).closest('form').submit()
)

$ ->
  $("*[id^='edit_reminder_'], [id='new_reminder']").submit ->
    $('#spinner, #overlay').show()
    return
  $(document).on('click', '.individual-reminder a', ()->
    $('#spinner, #overlay').show()
  )

  setTimeout( ()->
    if QueryString.reminder_id != null
      number = QueryString.reminder_id
      identifier = "[data-target='#collapse-reminder-"+ number + "']"
      if QueryString.open_reminder == 'true'
        $(identifier).click().closest('.list-group-item').effect( "highlight", {color:"rgba(30, 171, 244, 0.2)"}, 1000 )
      else
        $(identifier).closest('.list-group-item').effect( "highlight", {color:"rgba(30, 171, 244, 0.2)"}, 1000 )
  , 400 )


