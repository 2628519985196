AnswerNotification = {
  removeAN: ->
    $(this).siblings('input').attr('value', 1)
    $(this).parent().siblings('.form-group').hide(500)
    $(this).fadeOut 500, ->
      $(this).remove()
    element = $('<div/>').hide().append("<p> Please click on submit to save your changes or cancel to discard. </p>")
    $(this).parent().append(element)
    element.show(1000)
  ,

  createNewAN: ->
    element = $('<div/>').html($(this).attr('data-fields')).hide()
    $(this).closest('.form-group').after(element)
    element.show(500)

    $(this).fadeOut 500, ->
      $(this).remove()

    element = $('<div/>').hide()
    $(this).parent().append(element)
    element.show(1000)
  ,

  error: (xhrObj, textStatus, exception) ->
    alert('Sorry but something was wrong, please try again or contact your administrator.')
  ,

  replaceOptions: (data, requestStatus, xhrObject) ->
    $('#answer_notification_options').hide()
    $('#answer_notification_options').html(data).show(600)
  ,

  getOptions: ->
    ql_id = $(this).val()
    $.ajax({
      url: '/app_authorities/update_answer_notification_options',
      type: 'GET',
      data: { selected_questionnaire_line: ql_id },
      dataType: 'html',
      timeout: 25000,
      success: AnswerNotification.replaceOptions,
      error:   AnswerNotification.error
    })
  ,
  updateEmailsField: ->
    checkbox = $(this)
    checkbox_email = checkbox.siblings('span').find('label').attr('data-email')
    emails_field = checkbox.closest('.row.form-group').find('#emails_field')
    emails_field_value = emails_field.val()

    if checkbox.is(':checked')
      checkbox_email = checkbox_email + ', ' if emails_field_value.length > 0
      emails_field.val(checkbox_email + emails_field_value)
    else
      # found the email in the field area
      if (emails_field_value.indexOf(checkbox_email)!=-1)
        # delete the email in the field area
        delete_value_textarea(emails_field, checkbox_email)

  setup: ->
    $(document).on('click', '.create-answer-notification', AnswerNotification.createNewAN)
    $(document).on('click', '.remove-answer-notification', AnswerNotification.removeAN)
    $(document).on('change', '.answer_notification_selector [id^=app_authority_answer_notifications]', AnswerNotification.getOptions)
    $(document).on('change', '.multiselect .ANContact input[type="checkbox"]', AnswerNotification.updateEmailsField)
}

$(AnswerNotification.setup)

$ ->
  $(document).on 'click', '.multiselect li', (e)->
    e.stopPropagation()
